<script setup lang="ts">
import { SpDialog } from '@dev.smartpricing/sp-vue-components'
import {
	FeedbackModalOptions,
	FeedbackModalPhase,
	FeedbackModalStatus,
	FeedbackTransitionDirection,
} from '../../types/Feedback'
import { UserFeedback } from '../../../submodules/feedback/types/UserFeedback'

const emit = defineEmits<{
	(e: 'update:modelValue', value: boolean): void
}>()
const props = withDefaults(
	defineProps<{
		modelValue: boolean
		modalOptions: FeedbackModalOptions
		teleportTo?: string
		position: {
			top: string
			right: string
		}
	}>(),
	{
		teleportTo: '#dialogs-container',
	}
)
const dialogRef = ref<InstanceType<typeof SpDialog>>()
const dialogBodyRef = ref(null)

const { isMobile } = useMobileViewport()

const { height } = useElementSize(dialogBodyRef)

const modalPhase = ref<FeedbackModalPhase>(FeedbackModalPhase.Feedback)
const modalStatus = ref<FeedbackModalStatus>({
	chosenFeedback: undefined,
	chosenImprovements: undefined,
	chosenImprovementsOther: undefined,
})

const isOpen = computed({
	get: () => props.modelValue,
	set: (value: boolean) => emit('update:modelValue', value),
})

const modalTransitionDirection = ref(FeedbackTransitionDirection.Forward)
const modalTextAreaExpanded = ref(false)
const shouldRejectFeedback = ref(true)

const isFeedbackPhase = computed(() => modalPhase.value === FeedbackModalPhase.Feedback)
const isImprovementsPhase = computed(() => modalPhase.value === FeedbackModalPhase.Improvements)
const isThankYouPhase = computed(() => modalPhase.value === FeedbackModalPhase.ThankYou)

const transitionName = computed(() => {
	if (modalTransitionDirection.value === FeedbackTransitionDirection.Forward) {
		return 'slide-up'
	} else {
		return 'slide-down'
	}
})

const onExpandTextArea = (expand: boolean) => {
	modalTextAreaExpanded.value = expand
}

const onUpdateModalPhase = (phase: FeedbackModalPhase) => {
	if (Object.keys(FeedbackModalPhase).indexOf(modalPhase.value) < Object.keys(FeedbackModalPhase).indexOf(phase)) {
		modalTransitionDirection.value = FeedbackTransitionDirection.Forward
	} else {
		modalTransitionDirection.value = FeedbackTransitionDirection.Backward
	}

	// Only reject feedback on modal close if we're currently on the first
	// feebaack phase.
	if (phase === FeedbackModalPhase.Feedback) {
		shouldRejectFeedback.value = true
	} else {
		shouldRejectFeedback.value = false
	}

	modalPhase.value = phase
}

const onUpdateModalStatus = (status: FeedbackModalStatus) => {
	modalStatus.value = status
}

const onModalCloseWithoutFeedback = () => {
	dialogRef.value?.onCloseForced()

	onUpdateModalPhase(FeedbackModalPhase.Feedback)
	onUpdateModalStatus({
		chosenFeedback: undefined,
		chosenImprovements: undefined,
		chosenImprovementsOther: undefined,
	})
}

const onModalClose = (feedbackToGive?: UserFeedback) => {
	dialogRef.value?.onCloseForced()

	if (feedbackToGive) {
		useFeedbackStore().giveFeedback(feedbackToGive)
	} else {
		if (shouldRejectFeedback.value) {
			useFeedbackStore().rejectFeedback()
		}
	}

	onUpdateModalPhase(FeedbackModalPhase.Feedback)
	onUpdateModalStatus({
		chosenFeedback: undefined,
		chosenImprovements: undefined,
		chosenImprovementsOther: undefined,
	})
}

const dialogHeight = computed(() => {
	return height.value + 24 + 'px'
})

const customStyles = computed(() => {
	if (!isMobile.value) {
		return {
			top: props.position.top,
			right: props.position.right,
			height: dialogHeight.value,
		}
	}
	return {}
})
</script>

<template>
	<ClientOnly>
		<SpDialog
			ref="dialogRef"
			v-model="isOpen"
			:is-closable="modalPhase === FeedbackModalPhase.ThankYou"
			:teleport-to="props.teleportTo"
			:custom-options="{
				dialogContainer: 'lg:rounded-md lg:w-[358px] absolute z-[999999]',
				dialogBodyContainer: 'p-3 lg:p-3',
			}"
			:custom-styles="customStyles"
			@close="onModalCloseWithoutFeedback"
		>
			<div class="container lg:pt-3">
				<Transition :name="transitionName">
					<FeedbackFirstPhase
						class="absolute"
						style="width: 100%"
						ref="dialogBodyRef"
						v-if="isFeedbackPhase"
						:modal-options="props.modalOptions"
						:modal-status="modalStatus"
						:on-update-modal-phase="onUpdateModalPhase"
						:on-update-modal-status="onUpdateModalStatus"
						:on-modal-close="onModalClose"
					/>
					<FeedbackSecondPhase
						class="absolute"
						ref="dialogBodyRef"
						v-else-if="isImprovementsPhase"
						:modal-options="props.modalOptions"
						:modal-status="modalStatus"
						:on-update-modal-phase="onUpdateModalPhase"
						:on-update-modal-status="onUpdateModalStatus"
						:on-expand-text-area="onExpandTextArea"
						:on-modal-close="onModalClose"
					/>
					<FeedbackThirdPhase
						class="absolute"
						ref="dialogBodyRef"
						v-else-if="isThankYouPhase"
						:modal-status="modalStatus"
						:on-modal-close-without-feedback="onModalCloseWithoutFeedback"
					/>
				</Transition>
			</div>
		</SpDialog>
	</ClientOnly>
</template>

<style scoped>
.container {
	display: inline-block;
	position: relative;
}

.slide-up-enter-active,
.slide-up-leave-active {
	transition: all 0.3s ease-in-out;
}

.slide-up-enter-from {
	transform: translateY(100%);
}

.slide-up-leave-to {
	transform: translateY(-100%);
}

.slide-down-enter-active,
.slide-down-leave-active {
	transition: all 0.3s ease-in-out;
}

.slide-down-enter-from {
	transform: translateY(-100%);
}

.slide-down-leave-to {
	transform: translateY(100%);
}

#dialogs-container > div {
	z-index: 9999999;
}
</style>
