export type FeedbackModalOptions = {
	modalTitle: string
	modalHeader: string
	improvementsList: string[]
}

export type FeedbackModalStatus = {
	chosenFeedback: Feedback | undefined
	chosenImprovements: string[] | undefined
	chosenImprovementsOther: string | undefined
}

export type FeedbackModalImprovement = {
	isSelected: boolean
	label: string
}

export enum Feedback {
	SUPER_SAD = 'SUPER_SAD',
	SAD = 'SAD',
	NEUTRAL = 'NEUTRAL',
	HAPPY = 'HAPPY',
	SUPER_HAPPY = 'SUPER_HAPPY',
}

export enum FeedbackModalPhase {
	Feedback = 'Feedback',
	Improvements = 'Improvements',
	ThankYou = 'ThankYou',
}

export enum FeedbackTransitionDirection {
	Forward = 'Forward',
	Backward = 'Backward',
}

export enum FeedbackType {
	STRATEGY_CHANGE = 'Strategy change',
	BULK_PRICE_CUSTOMIZATION = 'Bulk price customization',
	SINGLE_PRICE_CUSTOMIZATION_CELL = 'Single price customization cell',
	SINGLE_PRICE_CUSTOMIZATION_SHEET = 'Single price customization sheet',
	EVENT_CONFIRMATION = 'Event confirm',
	ADD_3_OR_MORE_COMPETITORS = 'Add 3 or more competitors',
	COMPETITORS_CHART_INTERACTION = 'Competitors chart interaction',
	COMPETITORS_MAP_ZOOM_IN_OR_OUT = 'Competitors map zoom in or out',
	PRICE_LIST_SAVE = 'Price list save',
	DASHBOARD_KPIS_COMPARED = 'Dashboard KPIS compared',
	CHARTS_HOVER = 'Charts hover',
	ADVANCED_STRATEGY_CHANGE = 'Advanced strategy change',
}
