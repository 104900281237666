import { FeedbackType } from '../types/Feedback'

export const useFeedbackTracker = (eventKey: FeedbackType, improvementsCount: number) => {
	const isFeedbackDialogOpen = ref(false)

	const { activeFeedback } = storeToRefs(useFeedbackStore())
	watch(activeFeedback!, () => {
		if (activeFeedback?.value?.eventKey === eventKey) {
			isFeedbackDialogOpen.value = true
		}
	})

	const feedbackDialogOptions: ComputedRef<FeedbackModalOptions> = computed(() => {
		const options = {
			modalTitle: TranslationKeys[`feedback.${eventKey.replace(/\s+/g, '_').toLowerCase()}.TITLE` as TranslationKey],
			modalHeader: TranslationKeys[`feedback.${eventKey.replace(/\s+/g, '_').toLowerCase()}.HEADER` as TranslationKey],
			improvementsList: [] as string[],
		}

		for (let i = 1; i <= improvementsCount; i++) {
			options.improvementsList.push(
				TranslationKeys[`feedback.${eventKey.replace(/\s+/g, '_').toLowerCase()}.IMPROVEMENTS_${i}` as TranslationKey]
			)
		}

		return options
	})

	return {
		isFeedbackDialogOpen,
		feedbackDialogOptions,
	}
}
